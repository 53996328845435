@import '../../../assets/css/variables';

nav {
    .navMenuBtn {
        height: 20px;

        .menuTrigger {
            width: 18px;
            height: 2px;

            &::after,
            &::before {
                content: "";
                position: absolute;
                width: 18px;
                height: 2px;
                background-color: $black;
                right: 50%;
                transform: translateX(50%);
                transition: all .3s ease-in-out;
            }

            &::after {
                bottom: -5px;
            }

            &::before {
                top: -5px;
            }

            &.active {
                @include below-xl() {
                    visibility: hidden;
                }

                &::after {
                    width: 10px;
                    transform: rotate(-45deg);

                    @include above-xl() {
                        right: -2px;
                        bottom: -3px;

                    }

                    @include below-xl() {
                        visibility: visible;
                        width: 15px;
                        bottom: 0;
                        right: 0;
                    }
                }

                &::before {
                    width: 10px;
                    transform: rotate(45deg);

                    @include above-xl() {
                        right: -2px;
                        top: -3px;
                    }

                    @include below-xl() {
                        visibility: visible;
                        width: 15px;
                        top: 0;
                        right: 0;

                    }
                }

            }
        }

    }

    .mh-60 {
        min-height: 3.75rem;
    }

    .notificationDropdown {
        .dropdown-toggle {
            .customBadge {
                line-height: 0.601;
                margin-left: -1.1rem;
                margin-top: 6px;
            }

            &::after {
                display: none;
            }
        }
    }

    .notifMain {
        position: relative;
        .notifOpen {
            position: absolute;
            right: 0;
            width: 285px;
            padding: 0;
            background: #fff;
            border-radius: 5px;
            margin: 10px 0 0;
            border: 1px solid #ededed;
            z-index: 9999;
            max-height: 410px;
            overflow: auto;
            .notifRow {
                .notification-box-item {
                    padding: 10px 11px;
                    cursor: pointer;
                    .notifLbl {
                        color: #989ea5;
                        font-weight: normal;
                    }
                }
                
            }
        }
        .badge {
            min-width: 18px;
            min-height: 18px;
            right: -8px !important;
            left: auto !important;
            top: -5px !important;
            border-radius: 10px !important;
            border: 0 !important;
        }
    }



    .profileDropdown {
        .dropdown-toggle {
            &::after {
                border-top: 0.4em solid #000;
                border-right: 0.4em solid transparent;
                border-left: 0.4em solid transparent;
                top: 3px;
                position: relative;
            }
        }
    }
    .notification-box-item {
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 10px;
        .calendra-icon {
          vertical-align: top;
          margin-top: 3px;
          margin-right: 5px;
        }
    }
}