body{
    font-family: 'Figtree', sans-serif !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-30 {
    font-size: 30px !important;
}


.pt-30{
    padding-top: 30px;
}
.pt-50 {
    padding-top: 50px !important;
}
.pt-40 {
    padding-top: 40px !important;
}
.mw-84{
    min-width: 84px;
}

.mw-385 {
    max-width: 385px !important;
}
.mw-450{
    max-width: 450px !important;
}
.min-w-82{
    min-width: 82px;
}
.min-w-88{
    min-width: 88px;
}
.w-60 {
    width: 60px;
}
.h-60 {
    height: 60px;
}
.mh-auto {
    min-height: auto !important;
}



.loginBtn {
    position: relative;
    background: #fff;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.6s;
    overflow: hidden;
    border: 0 !important;
}
  .loginBtn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7d26cd;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
}
  .loginBtn:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;
  }
  .loginBtn div {
    position: relative;
    height: 18px;
    text-transform: uppercase;
    overflow: hidden;
    left: 0;
    top: 4px;
}
.loginBtn span {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.5s ease;
    text-align: center;
    left: 1px;
    right: 0;
    margin: 0 auto;
}
  .loginBtn span:first-child {
    color: #fff;
    transform: translateY(24px);
  }
  .loginBtn span:last-child {
    color: #7d26cd;
    transform: translateY(0);
  }
  /* .loginBtn:hover {
    background: #3ad2d0;
    transition: background 0.2s linear;
    transition-delay: 0.6s;
  } */
  .loginBtn:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;
  }
  .loginBtn:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }
  .loginBtn:hover span:first-child {
    transform: translateY(0);
  }
  .loginBtn:hover span:last-child {
    transform: translateY(-24px);
  }



  .registerBtn {
    position: relative;
    background: #7d26cd !important;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.6s;
    overflow: hidden;
    border: 0 !important;
}
  .registerBtn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
}
  .registerBtn:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7d26cd;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;
  }
  .registerBtn div {
    position: relative;
    height: 18px;
    text-transform: uppercase;
    overflow: hidden;
    left: 0;
    top: 4px;
}
.registerBtn span {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.5s ease;
    text-align: center;
    left: 1px;
    right: 0;
    margin: 0 auto;
}
  .registerBtn span:first-child {
    color: #7d26cd;
    transform: translateY(24px);
  }
  .registerBtn span:last-child {
    color: #fff;
    transform: translateY(0);
  }
  /* .registerBtn:hover {
    background: #3ad2d0;
    transition: background 0.2s linear;
    transition-delay: 0.6s;
  } */
  .registerBtn:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;
  }
  .registerBtn:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }
  .registerBtn:hover span:first-child {
    transform: translateY(0);
  }
  .registerBtn:hover span:last-child {
    transform: translateY(-24px);
  }

.topPortion {
    background: url(../../assets/images/topBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 768px;
}
.credTop a{
    min-width: 82px;
    min-height: 38px;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.3px;
}
.loginBtn{
    background: #fff !important;
    color: #7D26CD !important;
}
.registerBtn{
    background: #7D26CD !important;
    color: #fff !important;
    min-width: 105px !important;
}

.rightPromoTop {
    display: flex;
    align-items: center;
    justify-content: right;
}

.rightPromoTopImg {
    max-width: 454px;
}

.rightPromoTopImg img {
    width: 100%;
}

.leftPromoTop h3 {
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    margin: 0 0 10px;
}
.leftPromoTop i {
    margin: 5px 10px 0 0;
    color: #fff;
    font-size: 22px;
}
.leftPromoTop span {
    display: flex;
    align-items: flex-start;
}
.leftPromoTop span:nth-child(3) svg {
    width: 40px !important;
}

.leftPromoTop span:first-child svg {
    width: 40px;
}
.leftPromoTop svg {
    width: 28px;
    height: auto;
    margin: 0 10px 0 0;
}

.leftPromoTop p {
    font-size: 20px;
    color: #fff;
    font-weight: 300;
    line-height: 28px;
    margin: 10px 0 28px;
    padding: 0 12% 0 0;
}

.leftPromoTopLink a {
    margin: 0 16px 0 0;
}

.leftPromoTop {
    padding: 60px 0 0;
}
.containerXl{
    max-width: 1284px;
}

.promoSTwoInner h1 {
    font-size: 50px;
    color: #000;
    font-weight: 700;
    margin: 0 0 15px;
}
.promoSTwoInner p {
    font-size: 22px;
    color: #000;
    font-weight: 300;
    margin: 0 auto 15px;
    max-width: 78%;
    line-height: 28px;
}
.verticalData p {
    font-size: 22px;
    color: #fff;
    font-weight: 300;
    margin: 0 0 30px;
    max-width: 100%;
    line-height: 28px;
}
.promoSTwoInner .topArea {
    padding: 50px 0 30px;
}
.bottomArea {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.imgMobile {
    max-width: 945px;
    display: inline-block;
}

.imgMobile img {
    width: 100%;
}

.partialColumns {
    background: #7D26CD;
    color: #fff;
    padding: 40px 40px 20px 40px;
    border-radius: 30px;
    text-align: left;
    z-index: 99999;
    position: relative;
    /* margin-left: -69px;
    margin-right: -69px; */
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -150px;
    margin-bottom: -128px;
}

.partialContent {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 20px;
    display: flex;
    align-items: flex-start;
    line-height: normal;
}

.promoSthree {
    background: #250A3E;
    padding: 215px 0 0;
    min-height: 760px;
}

.bottomImgSec {
    display: inline-block;
    width: auto;
    float: right;
}

.bottomImgSec span {
    max-width: 805px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: 30px;
}

.bottomImgSec span img {
    width: 100%;
    right: -150px;
    position: relative;
}

.verticalData h2 {
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    margin: 0 0 18px;
}

.verticalData li {
    color: #fff;
    list-style: none;
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 18px;
    line-height: 28px;
    display: flex;
    align-items: flex-start;
}

.verticalData ul {
    padding: 0;
}
.verticalData {
    padding: 75px 0 0;
}
.verticalData li svg {
    margin: 4px 20px 0 0;
    min-width: 20px;
}
.partialContent svg {
    margin: 7px 20px 0 0;
    min-width: 20px !important;
    width: 20px !important;
    height: auto;
}
.mw-700{
    min-width: 700px;
}

.bottomStore {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 30px 0;
}

.bottomStore h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 23px;
    color: #000;
    line-height: 37px;
}

.bottomStoreInner a {
    margin: 0 10px;
}

.footerMain {
    background: #000;
    text-align: center;
    padding: 20px 0;
}

.footerMain p {
    color: #fff;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}
.logoMain img {
    width: 110px;
    height: auto;
}
.socialMain img {
    width: 60px !important;
    height: auto;
}
.socialMain a:first-child img {
    width: 40px !important;
    height: auto;
}
.bottomStore .container:last-child h3 {
    margin: 0 0 12px;
}

@media only screen and (max-width: 1600px) {
    .mw-700 {
            min-width: 620px;
        }

}


@media only screen and (max-width: 1199px) {

    .mw-700 {
        /* min-width: 475px; */
        min-width: calc(100% - -224px);
    }
    .leftPromoTop h3 {
        font-size: 28px;
    }
    .leftPromoTopLink img {
        max-width: 160px;
    }
    .leftPromoTop p {
        font-size: 18px;
    }
    .promoSTwoInner h1 {
        font-size: 36px;
    }
    .promoSTwoInner p {
        font-size: 19px;
    }
    .partialContent {
        font-size: 18px;
    }
    .verticalData h2 {
        font-size: 36px;
    }
    .verticalData p {
        font-size: 19px;
    }
    .verticalData li {
        font-size: 16px;
    }
    .verticalData li svg {
        margin: 7px 10px 0 0;
        min-width: 15px;
        width: 15px;
        height: auto;
    }


}
@media only screen and (max-width: 1080px) {
    .partialColumns {
        margin-left: -10px;
        margin-right: -10px;
    }
    .imgMobile {
        max-width: 800px;
    }
    .verticalData {
        padding: 30px 0 0;
    }

}


@media only screen and (max-width: 991px) {

    .bottomImgSec span {
        max-width: 590px;
    }
    .verticalData h2 {
        font-size: 30px;
        margin: 0 0 10px;
    }
    .verticalData p {
        font-size: 15px;
        padding-right: 40px;
        line-height: normal;
    }
    .verticalData li {
        font-size: 15px;
        padding-right: 40px;
        line-height: normal;
    }
    .leftPromoTop h1 {
        font-size: 35px;
    }
    .bottomImgSec span img {
        width: 88%;
        right: -200px;
    }
    .bottomImgSec {
        position: relative;
        top: 0px;
    }
    .leftPromoTop p {
        font-size: 14px;
        line-height: normal;
        padding: 0;
    }
    .leftPromoTopLink img {
        width: 110px;
    }
    .rightPromoTopImg {
        max-width: 260px;
    }
    .leftPromoTop {
        padding: 20px 0 0;
    }
    .topPortion {
        min-height: 550px;
    }
    .promoSTwoInner h1 {
        font-size: 30px;
    }
    .promoSTwoInner p {
        font-size: 16px;
        line-height: normal;
        max-width: 90%;
    }
    .partialContent {
        
        line-height: normal;
    }
    .promoSthree {
        min-height: 550px;
    }
    
    .bottomStore h3 {
        font-size: 24px;
        margin: 0 0 12px;
        line-height: normal;
    }
    .bottomStoreInner img {
        width: 110px;
    }
    
    .partialColumns {
        padding: 25px 40px 8px 40px;
        margin-top: -110px;
        margin-bottom: -125px;
        margin-left: -70px;
        margin-right: -70px;
    }

    .leftPromoTop h3 {
        font-size: 17px;
    }
    .partialContent svg {
        margin: 3px 10px 0 0;
        // min-width: 14px !important;
        width: 14px !important;
    }
    .promoSthree {
        padding: 180px 0 0;
    }

    
}

@media only screen and (max-width: 850px) {
    .partialColumns {
        margin-left: -10px;
        margin-right: -10px;
    }

    
}

@media only screen and (max-width: 767px) {
    .partialColumns {
        padding: 25px 20px 8px 20px;
    }
    .partialContent svg {
        min-width: 15px !important;
    }
    .bottomImgSec span {
        max-width: 520px;
    }
    .mw-700 {
        /*min-width: calc(100% - -160px);*/
        min-width: calc(100% + 236px);
    }
    .verticalData h2 {
        font-size: 24px;
    }
    .verticalData li {
        font-size: 13px;
    }
    .verticalData {
        padding: 50px 0 0;
    }
    .promoSthree {
        min-height: 470px;
        padding: 130px 0 0;
    }
    .partialContent {
        font-size: 13px;
    }
    .verticalData p {
        padding-right: 0;
    }
    .promoSTwoInner h1 {
        font-size: 24px;
    }
    .promoSTwoInner p {
        font-size: 15px;
    }
    .bottomImgSec span img {
        width: 70%;
        right: -250px;
    }
    .bottomImgSec {
        top: 55px;
    }
    .imgMobile {
        max-width: 450px;
    }
    .leftPromoTop h1 {
        font-size: 28px;
    }
    .leftPromoTop p {
        font-size: 13px;
        line-height: 18px;
    }

    .leftPromoTop h3 {
        font-size: 15px;
    }

    
}

@media only screen and (max-width: 667px) {
    .partialColumns {
        margin-left: -20px;
        margin-right: -20px;
    }

    
}

@media only screen and (max-width: 575px) {

        .order-sm-1{
            order: -1;
        }
        .rightPromoTop {
            justify-content: center;
        }
        .container {
            width: 90% !important;
        }
        .leftPromoTop p {
            padding: 0;
            font-size: 16px;
        }
        .leftPromoTopLink img {
            width: 110px;
        }
        .leftPromoTop {
            padding: 30px 0 50px;
        }
        .bottomImgSec {
            float: initial;
            text-align: center;
        }
        .bottomImgSec span {
            max-width: 100%;
        }
        .bottomImgSec span img {
            width: 75%;
            right: 0;
        }
        .verticalData {
            padding: 125px 0 30px;
        }
        .promoSthree {
            min-height: 760px;
            padding: 180px 0 0;
        }
        .partialColumns {
            margin-top: -82px;
            margin-bottom: -228px;
        }
        .partialContent {
            font-size: 15px;
        }
        .mw-700 {
            min-width: 100%;
        }
        .leftPromoTop h3 {
            font-size: 21px;
        }

    
}

@media only screen and (max-width: 480px) {
    .promoSthree {
        min-height: 710px;
    }
    .logoMain img {
        max-width: 100%;
    }
    .credTop a {
        min-width: 70px;
    }
    .registerBtn {
        min-width: 90px !important;
    }
    .leftPromoTopLink img {
        width: 110px;
    }
    .bottomStoreInner img {
        width: 110px;
    }
    .leftPromoTop h3 {
        font-size: 17px;
    }
    .leftPromoTop p {
        font-size: 14px;
    }
    .partialContent {
        font-size: 13px;
    }
    .partialContent svg {
        min-width: 12px !important;
        width: 12px !important;
    }
    .verticalData li svg {
        margin: 2px 10px 0 0;
        min-width: 13px;
        width: 13px;
    }

}

@media only screen and (max-width: 420px) {
    /* .header .d-flex {
        flex-direction: column;
    } */
    .logoMain {
        text-align: center;
        margin: 0 10px 0 0;
    }
    /* .credTop button {
        width: 48%;
    } */
    .credTop {
        width: 100%;
        /* margin: 15px 0 0; */
        display: flex;
        justify-content: right;
    }
    .credTop a {
        min-height: 34px;
    }
    .bottomStore h3 {
        font-size: 22px;
    }
    .verticalData h2 {
        font-size: 22px;
    }
    .promoSTwoInner h1 {
        font-size: 22px;
    }
    
    

}
