/* StripePaymentForm.css */
.payment-form {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .payment-form .form-group {
    margin-bottom: 15px;
  }
  
  .payment-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .card-number, .card-expiry, .card-cvc {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .payment-form .pay-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .payment-form .pay-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  