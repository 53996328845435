@import "./assets/css/variables";
@import "~bootstrap/scss/bootstrap";
:root {
    // Custom Toast CSS
    --toastify-color-dark: var(--bs-dark);
    --toastify-color-success: var(--bs-success);
    --toastify-color-error: var(--bs-danger);
    --toastify-icon-color-success: var(--bs-success);
    --toastify-icon-color-error: var(--bs-danger);
    --toastify-toast-min-height: 1.5rem;
    --toastify-font-family: 14px;
    --toastify-text-color-light: var(--bs-dark);
    --toastify-spinner-color: var(--bs-light);    
    --toastify-color-progress-success: var(--bs-success);
    --toastify-color-progress-error: var(--bs-danger);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
//Custom CSS


.fs-10 {
    font-size: $theme-size-10 !important;
}

.fs-11 {
    font-size: $theme-size-11 !important;
}

.fs-12 {
    font-size: $theme-size-12 !important;
}

.fs-13 {
    font-size: $theme-size-13 !important;
}

.fs-14 {
    font-size: $theme-size-14 !important;
}

.fs-15 {
    font-size: $theme-size-15 !important;
}

.fs-16 {
    font-size: $theme-size-16 !important;
}

.fs-18 {
    font-size: $theme-size-18 !important;
}

.fs-20 {
    font-size: $theme-size-20 !important;
}

.fs-30 {
    font-size: $theme-size-30 !important;
}


.pt-01{
    padding-top: 1px;
}
.pt-50 {
    padding-top: 50px !important;
}
.pt-40 {
    padding-top: 40px !important;
}

.mw-1 {
    min-width: 1px !important;
}

.mw-150 {
    max-width: 150px !important;
}
.mw-84{
    min-width: 84px;
}
.mw-290 {
    max-width: 290px !important;
}

.mw-385 {
    max-width: 385px !important;
}
.mw-450{
    max-width: 450px !important;
}
.min-w-82{
    min-width: 82px;
}
.min-w-88{
    min-width: 88px;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.30) !important;
}


.w-40 {
    width: 40px !important;
}

.min-h-28 {
    min-height: 28px !important;
}
.min-h-48 {
    min-height: 48px !important;
}

.h-35 {
    height: 35px !important;
}

.h-40 {
    height: 40px !important;
}
.w-64{
    width: 64px;
}
.width-100{
    width: 100px !important;
}
.h-64{
    height: 64px;
}
.w-60 {
    width: 60px;
}

.h-60 {
    height: 60px;
}
.height-100{
    height: 100px !important;
}
.mw-90 {
    min-width: 90px !important;
}

.mw-120 {
    min-width: 120px !important;
}

.mw-130 {
    min-width: 130px !important;
}
.mxw-90{
    max-width: 90px;
}

.mh-auto {
    min-height: auto !important;
}

.w-120 {
    width: 120px !important;
}

.w-150 {
    width: 150px !important;
}

.w-200 {
    width: 200px !important;
}
.mxw-600{
    max-width: 600px;
}

.w-300 {
    width: 300px !important;
}
label.MuiInputLabel-shrink{
    font-size: 13px !important;
}
.MuiInput-underline::before{
    border-bottom: 1px solid #949494 !important;
}

.credBtns{
    background: #7D26CD;
    &:hover{
        background: #7222bb;
    }
}
.greyBtn{
    color: $greyBtn;
}


.grey-color{
    color: $grey !important;
}
.Mui-error.grey-color,.MuiInputLabel-formControl.Mui-error{
    color: #d32f2f !important;
}
.removeBtn {
    background: #FF9898;
    border-color: #FF9898;
}
.removeBtn:hover, .removeBtn:focus, .removeBtn:active {
    background: #e97e7e;
    border-color: #e97e7e;
}
.customUpload{
    overflow: hidden;
    input{
        z-index: 1;
    }
    button{
        min-height: 27px;
        background: #D9D9D9;
        border-radius: 3px;
        border: none;
        text-transform: uppercase;
        top: 10px;
        right: 0;
        cursor: pointer;
        z-index: 2;
    }
}

.customCheck{
    .form-check-input{
        height: 18px;
        width: 18px;
        margin-right: 8px;
        border-color: #757575;
        margin-bottom: 2px;
        margin-top: 5px;
        border-width: 2px;
        &:checked{
            border-color: #7D26CD !important;
            background-color: #7D26CD !important;
        }
    }
}
.customTextarea textarea {
    max-height: 60px;
    overflow: auto !important;
}
a.relLinks {
    color: #6a3f91 !important;
}
#dropdown-notification {
    background: rgb(106 63 145 / 10%);
    width: 30px;
    height: 30px;
}
.custom-text-field label + div:after{
    border-color: $primary;
}
.custom-text-field {
    .Mui-focused{
        color: $primary !important;
    }
    label{
        &+.MuiInput-underline.MuiInputBase-colorPrimary {
            margin-top: 18px;
        }
    }
}

.theme-body-bg{
    background: $theme-body-bg;
}
.heading{
    color: $heading;
}
.sub-heading{
    color: $subheading;
}
.bg-black{
    background: $black;
}
.divider{
    background: $divider;
}
.bg-purple{
    background: $bgpurple;
}
.color-purple{
    color: $color-purple;
}
.light-grey{
    color: $lightgrey;
}
.lightBlue{
    background: $lightBlue;
}
.lightBlueColor{
    color: $lightBlue;
}
.profilebg {
    background: $profile-bg;
    img{
        // width: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }
}
.topRight{
    @include below-sm() {
        margin-top: 20px;
    }
    a{
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 6px;
        &:last-child{
            margin-left: 12px;

        }
        &.lightBlue{
            background: #0fbfd9;
            &:hover, &:focus, &:active {
                background: #09b2cb !important;
                border-color: #09b2cb !important;
            }

        }
    }
    
}
.listRow{
    div[class^="col-"]{
        padding-left: 0px;
    }
    @include below-sm() {
        margin-top: 8px !important;
        
    }
}
.regularbg{
    background: #ededed;
}
.border-grey{
    border-bottom: 1px solid #e5e5e5;
}
.detailVideo {
    & div{
        
    }
    .carousel{
        border-radius: 6px;
        overflow: hidden;
        padding: 0 40px;
        .carousel-indicators{
            display: none;
        }
        .carousel-inner{
            border-radius: 6px;
            .carousel-item {
                width: 100% !important;
                height: 240px;
                text-align: center;
                video {
                    width: 100%;
                    max-height: 100%;
                    margin: 0 auto;
                }
                img{
                    max-width: 100%;
                    margin: 0 auto;
                    max-height: 100%;
                    display: inline-block;
                }
            }
        }
        .carousel-control-prev {
            left: 0;
            padding: 0;
            width: auto;
            .carousel-control-prev-icon {
                background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23B9BEC2%27><path d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/></svg>");
            }
        }
        .carousel-control-next {
            right: 0;
            padding: 0;
            width: auto;
            .carousel-control-next-icon {
                background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23B9BEC2%27><path d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/></svg>");
            }
        }
    }
}

.detailSideCol {
    display: flex;
    width: 68px;
    border: 1px solid #CFCFCF;
    border-radius: 6px;
    height: 37px;
    align-items: center;
    padding: 0px;
    margin: 5px 15px 0 0;
    justify-content: center;
    overflow: hidden;
    span{
        width: auto;
        display: inline-block;
        height: 100%;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    
}
.margin-minus{
    margin-left: -15px;
    margin-right: -15px;
    
}
.franchiseDtl{
    padding: 0 30px;
    @include below-sm() {
        div[class^="col-"]{
            margin-bottom: 15px;
        }
    }
}
.logoCr{
    min-width: 227px;
    display: inline-block;
    min-height: 62px;
    img{
        width: 100%;
        max-width: 150px;
    }
}
.custoRadioMain {
    .left-col {
        width: 100%;
        display: inline-block;
        margin: 6px 0 0;
        .form-label {
            font-size: 16px;
            color: #717B85;
            font-weight: normal;
            margin: 0 0 9px;
        }
    }
    .theme-radio {
        margin-right: 60px !important;
        .form-check-input {
            width: 17px;
            height: 17px;
        }
        .form-check-input:checked {
            background-color: transparent;
            border-color: #6A3F91;        
            --bs-form-check-bg-image: url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%272%27 fill=%27%236A3F91%27/></svg>)
        }
        .form-check-label {
            padding: 1px 0 0 8px;
            font-size: 16px;
            color: #000;
        }
    }
    &.no-margin {
        .left-col {
            margin-top: 0;
        }
    }
    
}
.customDatePickerMain {
    fieldset {
        border: 0;
        border-bottom: 1px solid #949494;
        border-radius: 0;
        padding-left: 0;
        width: 100%;
    }
    .MuiTextField-root {
        width: 100%;
    }
    input {
        padding-bottom: 9px;
        padding-left: 0;
        font-size: 16px;
        color: #717B85;
        &::placeholder{
            font-size: 16px !important;
            color: #717B85;
            opacity: 9;
        }
    }
    .MuiButtonBase-root{
        padding-right: 5px;
        &:hover{
            background: transparent;
        }
        svg {
            width: 15px;
            height: 15px;
            margin: 10px 0 0;
            path {
                fill: #1C1B1F;
            }
        }
        .css-8je8zh-MuiTouchRipple-root{
            display: none;
        }
    }
}
.css-1qitunf-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px !important;
    color: #717B85 !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 16px !important;
    color: #000000DE !important;
}
.MuiFormHelperText-root.red {
    color: #d32f2f;
}

.profileuser {color: $profile-user;}
.bggreen{background: #05C2DE;}
.bgred{background: $bgred;}
.bgorange{background: $bgorange;}
.bgdarkgreen{background: $bgdarkgreen;}
.bg-white-tr{background: rgba($white, .2);}
.border-light{border-color: $border-light !important;}
.labelColor{color: $label-color;}

.privacyContent {
    h2 {
        font-size: 28px;
    }
    li {
        margin: 0 0 12px;
    }
    ol, ul {
        margin: 0 0 35px !important;
    }
}



//Custom Form Right Icon
.form-right-icon {
    .form-control {
        padding-right: 3rem;

        &.is-invalid,
        &:invalid {
            padding-right: 4.5rem;
            background-position: right 2.5rem center;
        }
    }

    .form-select {
        background-image: none;
    }
}


//Custom Tooltip Changes
.tooltip {
    position: fixed !important;

    .tooltip-inner {
        max-width: 300px;
    }

    .tooltipDottedList {
        li {
            &::before {
                content: "";
                width: 6px;
                height: 6px;
                background-color: $white;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 8px;
            }
        }
    }
}

//Sidebar Menu Backdrop showing below the 1199px screen size
.backdrop {
    display: none;

    @include below-xl() {
        display: block;
        z-index: 7;
        top: 60px;
    }
}

// Custom Scrollbar
@include above-xl() {
    * {
        scrollbar-width: thin;
        scrollbar-color: $secondary;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border-radius: 20px;

            &:hover {
                background-color: $primary;
            }
        }
    }
}



// .dataGridMain .MuiSelect-select {
//     min-width: 141px;
//     min-height: 30px;
//     padding-top: 0;
//     padding-bottom: 0;
//     display: flex;
//     align-items: center;
//     font-size: 13px;
//     color: #000 !important;
// }
// .dataGridMain .MuiInputBase-root.MuiOutlinedInput-root:hover fieldset {
//     border-color: #bcbcbc;
//     border-width: 1px;
// }
// .dataGridMain .MuiInputBase-root.MuiOutlinedInput-root fieldset {
//     border-radius: 3px;
//     height: 30px;
//     margin: 5px 0 0;
// }
// .dataGridMain .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-icon {
//     display: none;
// }
// .dataGridMain .MuiInputBase-root.MuiOutlinedInput-root fieldset legend {
//     display: none;
// }
// .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select::after {
//     content: '';
//     border: solid #717B85;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     padding: 3px;
//     transform: rotate(45deg);
//     -webkit-transform: rotate(45deg);
//     right: 10px;
//     position: absolute;
// }
.dateTopPanel {
    .MuiStack-root {
        width: 135px !important;
        margin: 0 0 0 auto;
        label {
            left: -15px !important;
            top: -2px;
        }
        input{
            padding-left: 0;
            font-size: 14px;
            padding-top: 12px;
            padding-bottom: 12px;
            &::placeholder{
                font-size: 14px !important;
            }
            &+ div{
                position: absolute;
                z-index: 999;
                right: 13px;
                left: 0;
                justify-content: end;
                height: 42px;
                top: 1px;
                max-height: inherit;
                margin-left: 0px;
                padding-top: 3px;
            
            }
        }
        
        .MuiButtonBase-root {
            color: #1C1B1F;
            padding-right: 0;
            &:hover{
                background: transparent !important;
            }
        }
        .Mui-focused{
            fieldset{
                border-color: $primary   !important;
            }
        }
    }
}
.dashboardTable {
    .MuiDataGrid-footerContainer{
        display: none;
    }
    .dashboardTableTop{
        .MuiFormControl-root {
            max-width: 100px;
            margin: 0 0 0 10px;
            &:first-child{
                width: 84px;
            }
            label{
                left: 0px;
                font-size: 13px !important;
                color: #000 !important;
                top: 4px;
            }
            .MuiInputBase-root.MuiInput-root {
                // border: 1px solid #D9D9D9;
                // border-radius: 5px;
                &::before{
                     border-bottom: 1px solid #D9D9D9 !important;
                 }
            }
            
        }

    }
    
}
.dataGridMainHeight{
    height: inherit !important;
}
.searchTopPanel {
    .MuiInputBase-root.MuiInput-root {
        margin-top: 20px;
    }
}


.paymentFormMain{
    .searchTopPanel{
        @include below-sm() {
            margin: 0 !important;
        }
    }
    .paymentImg {
        border-right: 1px solid #e7e7e7;
        @include below-sm() {
            border: 0 !important;
            justify-content: center !important;
        }
        img {
            max-width: 450px;
            @include below-sm() {
                max-width: 150px !important;
            }
        }
    }
    .paymentFormInner {
        @include below-sm() {
            padding: 0 !important;
        }
        .payment-form {
            border: 0;
            margin: 0;
            @include below-sm() {
                width: 100%;
            }
            label{
                font-size: 15px;
            }
            .StripeElement {
                border-bottom: 1px solid #717B85;
                border-radius: 0;
                padding: 12px 0 6px;
            }
            .StripeElement--focus {
                border-color: #6a3f91;
            }
            .pay-button {
                background: rgb(106, 63, 145);
                font-size: 16px;
                padding: 8px 10px;
                min-width: 92px;
            }
        }
    }
}




.dataGridMain {
    .MuiDataGrid-cell {
        font-size: 14px;
        color: #000;
    }
    .customSelectT {
        max-width: 141px;
        width: 100%;
    }
    .MuiDataGrid-root{
        box-shadow: none !important;
    }
	.MuiSelect-select {
		min-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		align-items: center;
		font-size: 13px;
		color: #000 !important;
        padding-left: 10px;
	}
	.MuiInputBase-root {
        
		&.MuiOutlinedInput-root {
			&:hover {
				fieldset {
					border-color: #bcbcbc;
					border-width: 1px;
				}
			}
			fieldset {
                &.MuiOutlinedInput-notchedOutline{
                    border-color: #E5E5E5 !important;
                    border-width: 1px !important;
                }
				border-radius: 3px;
				height: 30px;
				margin: 1px 0 0;
				legend {
					display: none;
				}
			}
			.MuiSelect-icon {
				display: none;
			}
		}
	}
    .MuiDataGrid-virtualScroller{min-height: 150px !important;}
}
.MuiDataGrid-virtualScrollerRenderZone{
    width: 100% !important;
}
.MuiOutlinedInput-input {
	&.MuiSelect-select {
		&::after {
			content: '';
			border: solid #717B85;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			right: 10px;
			position: absolute;
		}
	}
}






@include maxheight600() {
    .pageContentInner {
        height: inherit !important;
    }
}

::-webkit-input-placeholder {
  font-size: 20px !important;
  color: red;
}

:-ms-input-placeholder {
  font-size: 20px !important;
  color: red;
}

::placeholder {
  font-size: 20px !important;
  color: red;
}

.link-color-blue{
    color: $link-color-blue;
}

.thumb {
    display: inline-flex;
    vertical-align: top;
    position: relative;
    .thumbInner{
        width: 90px;
        display: flex;
        position: relative;
        margin: 0 12px 10px 0;
        border: 1px solid #e9e9e9;
        padding: 4px;
        border-radius: 5px;
        height: 90px;
        align-items: center;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }
    button {
        position: absolute;
        top: -7px;
        right: -7px;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        padding: 0;
        min-height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #6a3f91;
        border: 0;
    }
}

.customBrowseCv{
    padding: 16px 0 0;
    input {
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
    }
    button{
        height: 27px;
        background: #D9D9D9;
        border-radius: 3px;
        border: none;
        text-transform: uppercase;
        top: 10px;
        right: 0;
        cursor: pointer;
        &:hover, &:focus, &:active{
            background: #D9D9D9 !important;
        }
        @include below-sm() {
            right: 50px !important;
        }
    }
}

.paymentModal {
    .modal-dialog {
        
        .modal-header {
            border: 0;
            padding: 16px 18px;
            .btn-close{
                font-size: 9px;
                opacity: .3;
            }
        }
        .modal-body {
            padding: 0 18px 20px !important;
            .contentinner {
                border-radius: 10px;
                border: 1px solid #E9E9E9;
                padding: 16px 15px 18px !important;
                box-shadow: 0px 3px 13px #00000012;
                h3{
                    color: #6A3F91;
                    margin: 0 0 15px;
                }
                ul {
                    padding: 0;
                    list-style: none;
                    margin: 0 0 16px;
                    li {
                        display: flex;
                        align-items: start;
                        color: #000;
                        font-size: 13px;
                        margin: 0 0 2px;
                        svg {
                            margin: 5px 8px 0 0;
                            max-width: 8px;
                            width: 100%;
                        }
                    }
                }
                .payAmount {
                    font-size: 30px;
                    margin: 0 0 16px;
                }
                .payBtn {
                    width: 100%;
                    min-height: 36px;
                    font-size: 14px;
                    color: #fff;
                    padding: 6px !important;
                }
            }
        }
        
        
    }
}

.addRemoveBtn {
    @include below-lg() {
        position: absolute;
        right: 0;
        width: auto;
        top: 4px;
    }
    
}

.widthAdjustment{
    .col-md-6 {
        @include below-lg() {
            flex: 1;
        }
        @include below-sm() {
            flex: 0 auto;
        }
        input {
            @include below-lg() {
                padding-right: 18%;
            }
        }
    }  
    
    
}





//List Style
.MuiFormControl-root {
    .label {
        &.Mui-focused {
            font-size: 1rem !important;
        }

        &.MuiFormLabel-filled {
            font-size: 1rem !important;

            &:not(.Mui-focused) {
                color: $black;
            }
        }
    }

}

.theme-card-header {
    h5 {
        padding-left: 70px;

        .theme-icon-box {
            top: -36px;
        }
    }
}

.dataGridMain{
    .MuiDataGrid-columnHeaderTitle {
        color: #000;
    }
    .MuiDataGrid-row{
        .MuiDataGrid-cellContent {
            color: #000;
            font-size: 14px;
        }
        &.Mui-selected {
            background-color: transparent;
            &:hover {
				background-color: transparent;
			}
        }
    }
    
}

//Tabs Design
.theme-tab-header {
    .nav-tabs {
        .nav-item {
            padding-left: 4px;
            padding-right: 4px;

            &:not(:last-child) {
                margin-right: 20px;
            }

            .nav-link {
                padding-top: 14px;
                padding-bottom: .65rem;
                min-width: 120px;

                &:not(.active) {
                    color: $light;
                    font-weight: 400 !important;
                }
                &.active{border-color: $dark !important; 
                    background: transparent !important;
            }
            }
           
        }
    }
}
.customSwitch{
    .MuiSwitch-track {
            background-color: $lightgrey !important;
            opacity: 1 !important;
    }
    .MuiSwitch-thumb{
            background: $white;
            border: 1px solid $lightgrey;
    }
    .Mui-checked{
        background: transparent;
        .MuiSwitch-thumb{
            background: $white;
            border: 1px solid $toggle-color;
        }

        & + .MuiSwitch-track {
            background-color: $toggle-color !important;
            opacity: 1 !important;
        }
    }

}
.MuiTablePagination-root{
    .MuiTablePagination-toolbar{
        color: $paging;
        font-size: 13px;
        p{
            font-size: 13px !important;
        }
    }

}


.rounded-xs{
    border-radius: $btn-border-radius-xs;
}

.custom-datepicker{
    min-width: 100% !important;
    fieldset {
        border: 0 !important;
        border-bottom: 1px solid #949494 !important;
        border-radius: 0 !important;
    }
    input {
        padding-left: 8px;
        padding-top: 8px;
    }
    label{
        left: -6px !important;
    }
}

.credTopInfo {
    background: #05C2DE;
    border-radius: 8px;
    padding: 5px 10px;
    text-align: center;
    left: 0;
    right: 0;
    width: 86%;
    margin: 0 auto;
    top: -38px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        color: #fff;
        margin: 0;
        font-size: 20px;
    }
}
.credEye{
    position: absolute;
    right: 0;
    top: 15px;
    cursor: pointer;
}
.password-field {
    input {
        padding-right: 25px;
    }
}

.credCoverB{
    position: relative;
    .position-relative{
        width: 100%;
    }
    .inputIco {
        position: absolute;
        top: 23px;
        z-index: 999;
        color: #AAAAAA;
    }
    label{
        padding-left: 27px;
        &.MuiInputLabel-shrink {
            padding-left: 36px;
        }
    }
    input{
        padding-left: 27px;
    }
}
.inputIco.filled {
    color: #7D26CD; /* Change the color to your desired color */
  }
.thumbInner video {
    width: 100%;
}
.MuiDataGrid-overlay
{
    background-color: transparent !important;
}
.text_uppercase
{
    text-transform: capitalize;
}
.redColor
{
    color: red;
}
.greenColor
{
    color: green;

}
.close-icon-calendar{
    position: absolute;
    right: 36px;
    top: 20px;
    cursor: pointer;
    z-index: 999999;
}
.subscriptionModal .modal-dialog {
    max-width: 310px;
  }
  
  

.amount-paid{
float: right;font-weight: bold;color: #9400ff;
}